/**
 * Use the Logger by constructing it with the component name, and some
 * attributes that should be logged with every call.
 *
 * export const MyComponent() => {
 *   const [toggle, setToggle] = useState<bool>(false);
 *
 *   const log = Logger("MyComponent", {toggle});
 *
 *   useEffect(() => {
 *     log({message: "processing effect"})
 *   })
 * }
 *
 * When the useEffect is run, this should produce a structured log event of:
 *
 * {
 *   component: "MyComponent",
 *   toggle: false,
 *   message: "processing effect",
 * }
 *
 * This ensures our logs follow a consistent format, and work correctly with
 * hooked state.
 */
export const Logger = (component: string, attrs?: KeyVals) => {
  return (keyvals: KeyVals) => {
    /* eslint-disable-next-line no-console */
    console.debug({
      component,
      ...(attrs ? attrs : {}),
      ...keyvals,
    });
  };
};

// We're expecting log keys to be strings, even if we support any value
type KeyVals = Record<string, unknown>;
