import { Logger } from "src/common/logger";

import type {
  GoCardlessDropin as GoCardlessDropinInterface,
  GoCardlessDropinHandler,
  GoCardlessDropinOptions,
} from "./types";
import { EventName, parseEvent, postEvent } from "./events";
export type { GoCardlessDropinOptions };

export const GoCardlessDropin: GoCardlessDropinInterface = {
  create(options: GoCardlessDropinOptions): GoCardlessDropinHandler {
    const domain = resolveDomain(options);

    const log = Logger("GoCardlessDropin", {
      billing_request_flow_id: options.billingRequestFlowID,
    });

    const params: { mode: string; id?: string; template?: string } = {
      mode: "dropin", // don't include RuntimeMode, as that bloats the bundle
    };
    if (options.billingRequestTemplateID) {
      params.template = options.billingRequestTemplateID;
    }
    if (options.billingRequestFlowID) {
      params.id = options.billingRequestFlowID;
    }

    // Uniquely identify the iframe via this HTML ID
    const iframeID = `gocardless-dropin-iframe-${Math.random().toString(7)}`;

    // This is the iframe origin, which is what we'll use to restrict where we
    // receive our message from.
    const expectedOrigin = new URL(domain).origin;

    // This adds the iframe to the DOM, and starts the app. Until this is
    // called, the iframe is not visible.
    const open = (): void => {
      window.addEventListener("message", eventHandler);

      const query = new URLSearchParams(params).toString();
      document.body.appendChild(createIframe(iframeID, `${domain}?${query}`));
    };

    // This function should be called to terminate the Dropin. It ensures we
    // cleanup the iframe, and deregister the event listener- the latter ensures
    // subsequent Dropin instances won't collide with zombie listeners.
    const removeAndCleanup = (handler: (e: MessageEvent) => void) => {
      log({
        message: "removing Dropin iframe",
      });
      document.getElementById(iframeID)?.remove();

      log({
        message: "deregistering the event listener",
      });
      window.removeEventListener("message", handler);
    };

    // Name the eventHandler, as we'll want to remove it once we exit the flow.
    const eventHandler = (e: MessageEvent) => {
      const event = parseEvent({
        event: e,
        expectedOrigin,
      });
      if (!event) {
        return;
      }

      log({
        message: "received event",
        event_name: event.name,
        origin: expectedOrigin,
      });
      const iframe = document.getElementById(iframeID) as HTMLIFrameElement;
      switch (event?.name) {
        case EventName.AUTH_REQUEST:
          // BRF is requesting that we authenticate. We confirm our identity by
          // sending an AUTH_CONFIRM event, and restating the Billing Request
          // Flow ID, to prove we triggered the flow.

          log({
            message: "responding to AUTH_REQUEST with AUTH_CONFIRM",
          });
          if (!iframe?.contentWindow) {
            throw new Error(
              "failed to post event: iframe content window not found"
            );
          }
          postEvent({
            // Refresh the handle to the iframe, as contentWindow will be null
            // until we load it into the DOM.
            target: iframe.contentWindow,
            event: {
              name: EventName.AUTH_CONFIRM,
              payload: {
                origin: window.location.origin,
                billingRequestTemplateID: options.billingRequestTemplateID,
                billingRequestFlow: options.billingRequestFlowID
                  ? {
                      id: options.billingRequestFlowID,
                    }
                  : undefined,
              },
            },
          });

          return;

        case EventName.INIT:
          // We'll receive this event once the BRF has accepted our
          // authentication exchange.
          //
          // We don't need to do anything.
          log({
            message: "BRF sent INIT, we're ready to start the flow",
          });

          return;

        case EventName.SUCCESS:
          // We'll receive this event once the BRF is fulfilled
          log({
            message: "BRF sent SUCCESS, this means the flow is completed",
          });

          if (options.onSuccess) {
            options.onSuccess(
              event.payload.billingRequest,
              event.payload.billingRequestFlow
            );
          }

          return;

        case EventName.RETURN:
          log({
            message:
              "BRF sent RETURN, this means the flow is returning control to client",
          });
          removeAndCleanup(eventHandler);

          return;

        case EventName.EXIT:
          log({
            message:
              "received EXIT, triggering callback and terminating the flow",
          });
          removeAndCleanup(eventHandler);
          if (options.onExit) {
            options.onExit(null, {});
          }

          return;
      }
    };

    return {
      open,
      exit: () => {
        removeAndCleanup(eventHandler);
      },
    };
  },
};

const ENVIRONMENTS: { [key: string]: string } = {
  live: "https://pay.gocardless.com/billing/static/flow",
  sandbox: "https://pay-sandbox.gocardless.com/billing/static/flow",
  localhost: "http://localhost:3021/billing/static/flow",
  "sandbox-staging":
    "https://pay-sandbox-staging.gocardless.com/billing/static/flow",
  "sandbox-production":
    "https://pay-sandbox.gocardless.com/billing/static/flow",
  "live-staging": "https://pay-staging.gocardless.com/billing/static/flow",
};

const resolveDomain = (options: GoCardlessDropinOptions): string => {
  const domain = options.domain || ENVIRONMENTS[options.environment];
  if (!domain) {
    throw `GoCardlessDropin unrecognised environment: ${options.environment}`;
  }
  return domain;
};

// createIframe generates a fullpage iframe against the given source URL. The
// iframe is styled to cover all of the existing window.
const createIframe = (elementId: string, url: string) => {
  const iframe = document.createElement("iframe");

  iframe.setAttribute("src", url);
  iframe.setAttribute("id", elementId);
  iframe.setAttribute("name", elementId);
  iframe.setAttribute("height", "100%");
  iframe.setAttribute("width", "100%");
  iframe.setAttribute("allowtransparency", "true");
  iframe.setAttribute(
    "style",
    `
    position: fixed;
    inset: 0px;
    z-index: 2147483647;
    border-width: 0px;
    display: block;
    overflow: hidden auto;
    border: 0;
  `
  );

  return iframe;
};

// Expose onto the window, which has been extended via types.ts.
window.GoCardlessDropin = GoCardlessDropin;

export default GoCardlessDropin;
