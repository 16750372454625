import {
  BillingRequestResource,
  BillingRequestFlowResource,
} from "@gocardless/api/dashboard/types";

// We send events between Billing Request Flows and the Dropin client, using
// postMessage. This type is what we expect to send, and can be parsed at either
// end of that tunnel.
export interface Event {
  name: EventName;
  payload: {
    origin?: string;
    billingRequest?: BillingRequestResource;
    billingRequestTemplateID?: string;
    billingRequestFlow?: BillingRequestFlowResource;
  };
}

export enum EventName {
  // Billing Request Flow will ask the parent to authenticate:
  AUTH_REQUEST = "gc:auth_request",
  // Dropin client will respond to request with the confirmation of origin:
  AUTH_CONFIRM = "gc:auth_confirm",
  // Sent after successful authentication, indicating BRF has started:
  INIT = "gc:init",
  // Sent whenever the BRF completes without error:
  SUCCESS = "gc:success",
  // Sent whenever the BRF returns control to client:
  RETURN = "gc:return",
  // Sent when the BRF is requesting to exit:
  EXIT = "gc:exit",
}

// postEvent wraps a target postMessage, and should be used whenever the Billing
// Request Flow tries communicating with the Dropin. This ensures we send events
// of the correct schema expected by the client.
export const postEvent = ({
  event,
  target,
  targetOrigin = "*",
}: {
  event: Event;
  target: Window;
  targetOrigin?: string;
}) => {
  return target.postMessage(event, targetOrigin);
};

// parseEvent processes an event, then parses it into our event type, if it
// matches the preconditions like a specific origin.
//
// This function should be used to receive events from the Billing Request Flow
// application, running in an iframe.
export const parseEvent = ({
  event,
  expectedOrigin = "*",
}: {
  event: MessageEvent;
  expectedOrigin?: string;
}): Event | undefined => {
  if (expectedOrigin !== "*") {
    if (event.origin !== expectedOrigin) {
      // eslint-disable-next-line no-console
      console.debug(
        `event origin mismatch: event.origin ${event.origin} expectedOrigin ${expectedOrigin}`
      );
      return;
    }
  }

  // The event has come from a window.postMessage(...) call which could've been
  // called by any scripts running on the page or on its parent. This should be
  // expected, and ignored.
  if (!Object.values(EventName).includes(event.data?.name)) {
    return;
  }

  return event.data as Event;
};

// Wait to receive an event from the specified origin, of the specified type.
export const waitEvent = ({
  eventName,
  expectedOrigin,
}: {
  eventName: EventName;
  expectedOrigin: string;
}): Promise<Event> => {
  return new Promise((resolve) => {
    window.addEventListener(
      "message",
      (messageEvent: MessageEvent) => {
        const event = parseEvent({ event: messageEvent, expectedOrigin });
        if (event && event.name === eventName) {
          resolve(event);
        }
      },
      {}
    );
  });
};
